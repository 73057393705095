<template>
  <div>
    <v-row>
      <v-col cols="8" xs="12">
        <breadcrumbs :items="dataBreadcrumbs" />
      </v-col>
      <v-col cols="4" xs="12" align="right">
      </v-col>
    </v-row>

    <v-row>
      <v-col xs="12" class="col-xs-12">
        <h2 style="font-size: 25px !important;">Liste des Colis</h2>
      </v-col>
      <v-col xs="12" class="col-xs-12">
      </v-col>
    </v-row>


    <v-row>
      <v-col lg="4">
        <v-text-field v-model="searchQuery" label="Filtre"></v-text-field>
      </v-col>
      <v-col lg="2">
        <v-select
            label="Statut"
            v-model="search.status"
            :items="statues"
            item-value="id"
            item-text="label"
            @change="fetchItems"
        ></v-select>
      </v-col>
      <v-col lg="3">
        <v-menu
            v-model="menu2"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
                v-model="interval.date1"
                label="Du"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
              v-model="interval.date1"
              @input="menu2 = false"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col lg="3">
        <v-menu
            v-model="menu3"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
                v-model="interval.date2"
                label="Au"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
              v-model="interval.date2"
              @input="menu3 = false"
          ></v-date-picker>
        </v-menu>
      </v-col>

    </v-row>

    <v-data-table
        :loading="loading"
        :headers="headers"
        :items="orderll.listEntity"
        class="elevation-0"
        :page.sync="orderll.current_page"
        :server-items-length="orderll.nb_element"
        :sort-by="['id']"
        @page-count="pageCount = $event"
        hide-default-footer
        :sort-desc="true"
    >
      <template v-slot:item.status="{ item }">
        <v-chip
            :color="item.status.color"
            :class="`v-chip-light-bg text-sm font-weight-semibold  text-capitalize`"
        >{{ item.status.label }}</v-chip>
      </template>
      <template v-slot:top>
        <v-toolbar
            flat>
          <v-dialog
              v-model="dialog"
              style="box-shadow: none !important;"
              max-width="900px">
            <v-card-text>
              <fiche-colis ref="content" :orderdetail="orderdetail" />
            </v-card-text>
          </v-dialog>

          <v-dialog v-model="dialogAffectation">
            <v-card>
              <v-list three-line>
                <template v-for="(item) in affiliates">
                  <v-list-item
                      :key="item.title"
                  >
                    <v-list-item-avatar>
                      <v-btn class="primary" @click="confirmAssign(item)">Assigner</v-btn>
                    </v-list-item-avatar>

                    <v-list-item-content>
                      <v-list-item-title v-html="item.user.email"></v-list-item-title>
                      <v-list-item-subtitle v-html="item.commission"></v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-list>
            </v-card>
          </v-dialog>

        </v-toolbar>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-btn-toggle
            dense
            dark
            class="primary"
        >
          <!--            toogle booton validation and view training bill-->

          <v-btn @click="detail(item)" small class="primary">
            <v-icon small class="mr-2">mdi-receipt</v-icon>
            Ticket
          </v-btn>
          <v-btn :to="'/detail/course/'+item.id" small class="primary">
            <v-icon small class="mr-2">mdi-eye</v-icon>
            Détail
          </v-btn>
          <v-btn v-if="false" @click="affectation(item)" small
                 class="primary">
            <v-icon
                small
                class="mr-2"
            >
              mdi-plus
            </v-icon>
            Affecter
          </v-btn>
        </v-btn-toggle>
      </template>

    </v-data-table>

    <v-card-text class="pt-2">
      <v-row>
        <v-col
            lg="2"
            cols="3"
        >

          <v-select
              v-model="orderll.per_page"
              label="Lignes par page:"
              :items="[10,20,30,50,80,100, 'Toutes']"
              hide-details
              @change="fetchItems()"
          ></v-select>

        </v-col>

        <v-col
            lg="10"
            cols="9"
            class="d-flex justify-end"
        >
          <v-pagination
              v-model="orderll.current_page"
              total-visible="6"
              :length="parseInt(orderll.pagination )"
              @input="fetchItems()"
          ></v-pagination>
        </v-col>
      </v-row>
    </v-card-text>
  </div>
</template>

<script>
import breadcrumbs from "@/components/ui/breadcrumbs";
import {Drequest} from "@/plugins/Drequest";
import FicheColis from "@/components/FicheColis";

const moonLanding = new Date();
export default {
  name: "OrderList",
  components: {FicheColis, breadcrumbs},

  data: () => ({
    loadingCard: true,
    interval: {
      company_id: "",
      date1: moonLanding.getFullYear() + "-01-01",
      date2: moonLanding.getFullYear() + "-12-31"
    },
    searchQuery: "",
    menu2: false,
    menu3: false,
    loading: false,
    dialog: false,
    dialogAffectation: false,
    dialogDelete: false,
    headers: [
      {text: 'Enregistré le', value: 'created_at'},
      {text: 'Affecté à', value: 'transporter.name', sortable: false},
      {text: 'Pays', value: 'transporter.countries[0].iso', sortable: false},
      {text: 'Statut', value: 'status.label'},
      {text: 'Livraison', value: 'deliverymode'},
      {text: 'Tariff', value: 'shippingcost', sortable: false},
      {text: 'Actions', value: 'actions', sortable: false},
    ],
    // countries: [],
    search: {
      status: 1,
      deliverymode: [],
      coverage: [],
    },
    supplements: [],
    deliverymodes: [],
    items: [],
    statues: [],
    deliverymode: [],
    affiliates: [],
    orderll: {},
    editedIndex: -1,
    editedItem: {},
    defaultItem: {},
    orderdetail: {},
    apiorder: ''
  }),
  props: ["affiliate", "transporter", "countries"],
  created() {
    this.dataBreadcrumbs = [
      {
        text: 'Accueil',
        disabled: false,
        href: '/dashboard',
      },
      {
        text: 'Gestion des Colis (Courses)',
        disabled: true,
        href: '/',
      }
    ];
    this.initialize();
  },
  watch: {
    affiliate(val) {
      if (val)
        this.apiorder = "affiliate.id:eq=" + val.id
    },
    transporter(val) {
      if (val)
        this.apiorder = "transporter.id:eq=" + this.$store.state.transporter.id
    }
  },
  methods: {
    fetchItems() {
      this.loading = true;
      Drequest.api("lazyloading.order?dfilters=on&" + this.apiorder)
          .param({
            //"status.id:eq": this.search.status,
            next: this.orderll.current_page,
            per_page: this.orderll.per_page,
          })
          .get((response) => {
            this.loading = false;
            this.orderll = response;
          })
    },
    initialize() {
      this.loading = true;
      if (this.transporter) {
        Drequest.api("dcollection")
            .data({
              "lazyloading.affiliate": "dfilters=on&transporter.id:eq=" + JSON.parse(this.$store.state.transporter).id,
              "lazyloading.order": "dfilters=on&transporter.id:eq=" + JSON.parse(this.$store.state.transporter).id,
              "lazyloading.status": "dfilters=on&entity.name:eq=order",
            })
            .raw((response) => {
              this.loading = false;
              this.orderll = response["order_ll"];
              this.statues = response["status_ll"].listEntity;
              this.affiliates = response["affiliate_ll"].listEntity;
              this.items = this.orderll.listEntity;
              this.loadingCard = false;
              // this.close();
            })
      } else {
        Drequest.api("dcollection")
            .data({
              "lazyloading.order": "dfilters=on&next=1&per_page=15&user.id:eq=" + JSON.parse(this.$store.state.user).id,
              "lazyloading.status": "dfilters=on&entity.name:eq=order",
            })
            .raw((response) => {
              this.loading = false;
              this.orderll = response["order_ll"];
              this.statues = response["status_ll"].listEntity;
              this.items = this.orderll.listEntity;
              this.loadingCard = false;
              // this.close();
            })
      }


      Drequest.api("lazyloading.tree-item?dfilters=on&tree.name:eq=deliverymode")
          .get((response) => {
            this.deliverymodes = response.listEntity;
          })

    },
    detail(item) {
      this.orderdetail = item
      this.dialog = true
    },
    affectation(item) {
      console.log(item)
      this.dialogAffectation = true
    },
    confirmAssign(item) {
      console.log(item)
    },
    close() {

    }
  }
}
</script>


<style scoped>
@import "~bootstrap/dist/css/bootstrap.min.css";

.v-dialog {
  border-radius: 5px;
  margin: 24px;
  overflow-y: auto;
  pointer-events: auto;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  width: 100%;
  z-index: inherit;
  box-shadow: none !important;
}
</style>
