var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"8","xs":"12"}},[_c('breadcrumbs',{attrs:{"items":_vm.dataBreadcrumbs}})],1),_c('v-col',{attrs:{"cols":"4","xs":"12","align":"right"}})],1),_c('v-row',[_c('v-col',{staticClass:"col-xs-12",attrs:{"xs":"12"}},[_c('h2',{staticStyle:{"font-size":"25px !important"}},[_vm._v("Liste des Colis")])]),_c('v-col',{staticClass:"col-xs-12",attrs:{"xs":"12"}})],1),_c('v-row',[_c('v-col',{attrs:{"lg":"4"}},[_c('v-text-field',{attrs:{"label":"Filtre"},model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}})],1),_c('v-col',{attrs:{"lg":"2"}},[_c('v-select',{attrs:{"label":"Statut","items":_vm.statues,"item-value":"id","item-text":"label"},on:{"change":_vm.fetchItems},model:{value:(_vm.search.status),callback:function ($$v) {_vm.$set(_vm.search, "status", $$v)},expression:"search.status"}})],1),_c('v-col',{attrs:{"lg":"3"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Du","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.interval.date1),callback:function ($$v) {_vm.$set(_vm.interval, "date1", $$v)},expression:"interval.date1"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu2),callback:function ($$v) {_vm.menu2=$$v},expression:"menu2"}},[_c('v-date-picker',{on:{"input":function($event){_vm.menu2 = false}},model:{value:(_vm.interval.date1),callback:function ($$v) {_vm.$set(_vm.interval, "date1", $$v)},expression:"interval.date1"}})],1)],1),_c('v-col',{attrs:{"lg":"3"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Au","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.interval.date2),callback:function ($$v) {_vm.$set(_vm.interval, "date2", $$v)},expression:"interval.date2"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu3),callback:function ($$v) {_vm.menu3=$$v},expression:"menu3"}},[_c('v-date-picker',{on:{"input":function($event){_vm.menu3 = false}},model:{value:(_vm.interval.date2),callback:function ($$v) {_vm.$set(_vm.interval, "date2", $$v)},expression:"interval.date2"}})],1)],1)],1),_c('v-data-table',{staticClass:"elevation-0",attrs:{"loading":_vm.loading,"headers":_vm.headers,"items":_vm.orderll.listEntity,"page":_vm.orderll.current_page,"server-items-length":_vm.orderll.nb_element,"sort-by":['id'],"hide-default-footer":"","sort-desc":true},on:{"update:page":function($event){return _vm.$set(_vm.orderll, "current_page", $event)},"page-count":function($event){_vm.pageCount = $event}},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{class:"v-chip-light-bg text-sm font-weight-semibold  text-capitalize",attrs:{"color":item.status.color}},[_vm._v(_vm._s(item.status.label))])]}},{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-dialog',{staticStyle:{"box-shadow":"none !important"},attrs:{"max-width":"900px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card-text',[_c('fiche-colis',{ref:"content",attrs:{"orderdetail":_vm.orderdetail}})],1)],1),_c('v-dialog',{model:{value:(_vm.dialogAffectation),callback:function ($$v) {_vm.dialogAffectation=$$v},expression:"dialogAffectation"}},[_c('v-card',[_c('v-list',{attrs:{"three-line":""}},[_vm._l((_vm.affiliates),function(item){return [_c('v-list-item',{key:item.title},[_c('v-list-item-avatar',[_c('v-btn',{staticClass:"primary",on:{"click":function($event){return _vm.confirmAssign(item)}}},[_vm._v("Assigner")])],1),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"innerHTML":_vm._s(item.user.email)}}),_c('v-list-item-subtitle',{domProps:{"innerHTML":_vm._s(item.commission)}})],1)],1)]})],2)],1)],1)],1)]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn-toggle',{staticClass:"primary",attrs:{"dense":"","dark":""}},[_c('v-btn',{staticClass:"primary",attrs:{"small":""},on:{"click":function($event){return _vm.detail(item)}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v("mdi-receipt")]),_vm._v(" Ticket ")],1),_c('v-btn',{staticClass:"primary",attrs:{"to":'/detail/course/'+item.id,"small":""}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v("mdi-eye")]),_vm._v(" Détail ")],1),(false)?_c('v-btn',{staticClass:"primary",attrs:{"small":""},on:{"click":function($event){return _vm.affectation(item)}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v(" mdi-plus ")]),_vm._v(" Affecter ")],1):_vm._e()],1)]}}])}),_c('v-card-text',{staticClass:"pt-2"},[_c('v-row',[_c('v-col',{attrs:{"lg":"2","cols":"3"}},[_c('v-select',{attrs:{"label":"Lignes par page:","items":[10,20,30,50,80,100, 'Toutes'],"hide-details":""},on:{"change":function($event){return _vm.fetchItems()}},model:{value:(_vm.orderll.per_page),callback:function ($$v) {_vm.$set(_vm.orderll, "per_page", $$v)},expression:"orderll.per_page"}})],1),_c('v-col',{staticClass:"d-flex justify-end",attrs:{"lg":"10","cols":"9"}},[_c('v-pagination',{attrs:{"total-visible":"6","length":parseInt(_vm.orderll.pagination )},on:{"input":function($event){return _vm.fetchItems()}},model:{value:(_vm.orderll.current_page),callback:function ($$v) {_vm.$set(_vm.orderll, "current_page", $$v)},expression:"orderll.current_page"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }